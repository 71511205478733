<template>
  <div class="_bg-default _80w _100vh">
    <div v-if="portfolio" class="_100top py-3">
      <v-row>
        <v-col cols="12" md="4">
          <v-card
            height="210"
            class="radius-card"
            elevation="0"
            to="/psikolog/portfolio/create"
          >
            <div
              class="d-flex flex-column justify-center align-center"
              style="height: 100%"
            >
              <v-btn fab depressed color="blue lighten-4"
                ><v-icon size="50" color="blue">mdi-plus</v-icon></v-btn
              >
              <h2 class="mt-3">Add Portofolio</h2>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-container class="pa-3">
        <v-row>
          <v-col
            cols="12"
            md="23"
            v-for="item in portfolio.data"
            :key="item.id"
          >
            <v-card class="radius-card pa-3" elevation="0">
              <div
                v-if="item.type == 'Project Based'"
                class="d-flex justify-space-between align-center"
              >
                <h3>{{ item.type }}</h3>
                <v-btn fab depressed color="pink lighten-5" class="ml-2">
                  <v-icon color="pink">mdi-account-hard-hat</v-icon>
                </v-btn>
              </div>
              <div
                v-if="item.type == 'Individual Service'"
                class="d-flex justify-space-between align-center"
              >
                <h3>{{ item.type }}</h3>
                <v-btn fab depressed color="blue lighten-5" class="ml-2">
                  <v-icon color="blue">mdi-account-tie</v-icon>
                </v-btn>
              </div>
              <div class="mt-3">
                <v-row>
                  <v-col cols="5" md="2" class="pt-0">Project Category </v-col>
                  <v-col cols="7" md="10" class="pt-0"
                    >: {{ item.kategori }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" md="2" class="pt-0">Service</v-col>
                  <v-col cols="7" md="10" class="pt-0"
                    >: {{ item.layanan }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" md="2" class="pt-0">Position</v-col>
                  <v-col cols="7" md="10" class="pt-0"
                    >: {{ item.position }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" md="2" class="pt-0">Description</v-col>
                  <v-col cols="7" md="10" class="pt-0"
                    >: {{ item.deskripsi }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" md="2" class="pt-0">Client</v-col>
                  <v-col cols="7" md="10" class="pt-0"
                    >: {{ item.klien }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" md="2" class="pt-0">Location</v-col>
                  <v-col cols="7" md="10" class="pt-0"
                    >: {{ item.location }}
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "portfolio",

  data() {
    return {
      currentPage: 1,
      limit: 10,
      valueDeterminate: 50
    };
  },
  computed: {
    ...mapState({
      portfolio: state => state.psycholog.listPortfolio,
      env: state => state.API_URL,
      role: state => state.role,
      id: state => state.id
    })
  },
  mounted() {
    this.fetchItem();
  },
  methods: {
    fetchItem() {
      this.$store.dispatch("psycholog/listPortfolio", this.id);
    },
    deleteItem(item) {
      //delete  function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          this.$store.dispatch("survey/deleteSurvey", item.id).then(() => {
            this.fetchItem(); //if success refetch data all
            Swal.fire({
              title: "Deleted",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true
            });
          });
        }
      });
    }
  }
};
</script>

<style scoped></style>
